import axios from "axios";
import { localePrefix } from "./helpers";

export class API {
  static endpoints = {
    release: {
      create: {url: '{locale}/releases', method: 'post'},
      update: {url: '{locale}/releases/{id}', method: 'put'},
      finalize: {url: '{locale}/releases/{id}/checkout', method: 'put'},
      lock: {url: '{locale}/releases/{id}/lock', method: 'put'},
      unlock: {url: '{locale}/releases/{id}/unlock', method: 'put'},
      notifications: {url: '{releaseUrl}/release_notifications', method: 'post'},
      translate: {url: '{locale}/releases/translate', method: 'post'},
    },
    users: {
      login: {url: '{locale}/users/sign_in', method: 'post'},
      signup: {url: '{locale}/users', method: 'post'},
      resetPassword: {url: '{locale}/users/password', method: 'post'},
    },
    uploads: {
      companionUrl: '/',
    },
  };

  static parseFile = {
    fromPageData: input => {
      let filename = '';
      try{filename = input.file.metadata.filename} catch(e){
        try{filename = input.file.original.metadata.filename} catch(e){}
      }
      return {
        file_id: input.file_id,
        id: input.id,
        url: input.url,
        name: filename,
        description: input.description || '',
        content: input.content,
        apiData: {
          description: input.description || '',
          id: input.id,
          file: input.file,
          // url: input.url,
        }
      }
    },
    fromUppy: input => {
      const keyData = input.meta.key.match(/^([^/]*)\/(.*)$/);
      return {
        uppyId: input.id,
        file_id: keyData[2],
        id: null,
        url: null,
        name: input.name,
        description: '',
        apiData: {
          description: '',
          file: {
            id: keyData[2],
            storage: keyData[1],
            metadata: {
              size: input.size,
              filename: input.name,
              mime_type: input.type,
            },
          },
        },
      };
    },
    fromUpdateAPI: input => {
      return API.parseFile.fromPageData(input);
    },
    forUpdateAPI: file => {
      return {
        ...file.apiData,
        file: JSON.stringify({
          ...file.apiData.file,
          ...(!file.id && {
            media_kit: file.inMediaKit,
            content: file.isContent,
          }),
        }),
        description: file.description,
        // ...(!file.id && {
        //   media_kit: file.inMediaKit,
        //   content: file.isContent,
        // }),
      };
    },
  };

  static call = ({endpoint, params, data, suffix = ''}) => {
    return axios({
      url: this.prepareUrl(endpoint.url, params) + (suffix ? `?${suffix}` : ''),
      method: endpoint.method || 'get',
      data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
    });
  };

  static prepareUrl = (pattern, params) => {
    const paramsWithLocale = {
      locale: localePrefix(),
      ...params,
    };

    let url = pattern;
    Object.entries(paramsWithLocale).forEach(([key, value]) => {
      url = url.replace(new RegExp(`\{${key}\}`, 'g'), value);
    });
    return url;
  };
};
